/* -----------------------------
   ADMIN PAGE BASE STYLES
------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  background: #f5f7fa;
  font-family: 'Roboto', sans-serif;
  color: #4a4a4a;
  margin: 0;
  padding: 0;
}

/* Main container */
.admin-container {
  max-width: 1200px;
  margin: 30px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  padding: 20px;
}

.admin-container h2 {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  font-weight: 700;
  color: #333;
}

/* Controls section (Search, Filter, Select All) */
.controls-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 10px;
  margin-bottom: 25px;
  align-items: center;
}

.search-bar-container input {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.select-all-container {
  display: flex;
  align-items: center;
}

.select-all-container input[type="checkbox"] {
  margin-right: 6px;
  transform: scale(1.3);
  cursor: pointer;
}

/* Product Filter dropdown */
.filter-by-productId select {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* -----------------------------
   NOTIFICATIONS / MESSAGES
------------------------------*/
.email-status-message,
.feedback-email-status-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #17a2b8;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeInOut 4s forwards;
}
@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

/* -----------------------------
   LEADER LIST
------------------------------*/
.customer-list {
  margin-top: 10px;
}
.customer-list h3 {
  margin-bottom: 15px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

/* Each leader item is a card */
.customer-item {
  background: #f9f9f9;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.07);
  transition: transform 0.2s ease;
}
.customer-item:hover {
  transform: translateY(-2px);
}

/* If the leader is ready to notify participants, highlight */
.customer-item-ready {
  border-left: 5px solid #28a745;
  background-color: #f0fff0;
}

/* Email indicators */
.email-sent-indicator {
  color: #28a745;
  margin-left: 10px;
}
.email-not-sent-indicator {
  color: #dc3545;
  margin-left: 10px;
}

/* Additional data styling */
.completion-percentage {
  margin-top: 5px;
  font-size: 0.95rem;
  color: #444;
}
.notification-status {
  display: inline-block;
  margin-top: 5px;
  background-color: #28a745;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}

/* Buttons in each card */
.customer-item button {
  background-color: #3498db;
  color: #fff;
  border: none;
  margin-top: 6px;
  margin-right: 8px;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
  font-size: 0.9rem;
}
.customer-item button:hover {
  background-color: #2980b9;
}
.customer-item button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Right-aligned email history */
.right-aligned {
  float: right;
}
.email-history {
  margin-top: 5px;
}

/* Show/hide expanded participants */
.customer-details {
  margin-top: 10px;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 5px;
}

/* No raters message */
.no-raters {
  color: #e74c3c;
  font-weight: 600;
  margin-top: 8px;
}

/* -----------------------------
   CUSTOM MESSAGE TEXTAREA
------------------------------*/
textarea.custom-message {
  display: block;
  margin-top: 5px;
  margin-bottom: 8px;
  width: 95%;
  max-width: 600px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 0.95rem;
  resize: vertical;
}
textarea.custom-message:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 3px rgba(52,152,219,0.4);
}

/* -----------------------------
   PARTICIPANT LIST
------------------------------*/
.completed,
.incomplete {
  display: inline-block;
  margin: 0 5px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.85rem;
  color: #fff;
}
.completed {
  background-color: #28a745;
}
.incomplete {
  background-color: #dc3545;
}

/* -----------------------------
   PAGINATION
------------------------------*/
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 10px 0 20px 0;
}
.page-item {
  margin: 0 3px;
}
.page-item a.page-link {
  text-decoration: none;
  color: #007bff;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.15s;
}
.page-item a.page-link:hover {
  background-color: #f0f0f0;
}
.page-item.active a.page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* -----------------------------
   BATCH MESSAGE 
------------------------------*/
.batch-custom-message {
  margin-top: 10px;
}
.batch-custom-message textarea.custom-message {
  width: 100%;
  max-width: 400px;
  margin-bottom: 8px;
}

/* -----------------------------
   MODAL
------------------------------*/
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  background: #fff;
  outline: none;
  border-radius: 8px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 999;
}

.Modal button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 14px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-top: 10px;
}
.Modal button:hover {
  background-color: #2980b9;
}

/* Fade-in/out notifications keyframe is above */

