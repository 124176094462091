.bulk-upload-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fdfdfd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .bulk-upload-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .upload-type-selection,
  .leader-selection,
  .upload-options {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  select,
  textarea,
  button {
    font-size: 16px;
  }
  
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    resize: vertical;
  }
  
  .upload-options {
    text-align: center;
  }
  
  .upload-options p {
    margin: 10px 0;
    font-weight: bold;
  }
  
  .upload-actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  
  .upload-button,
  .sample-button {
    flex: 1;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .sample-button {
    background-color: #28a745;
  }
  
  .sample-button:hover {
    background-color: #218838;
  }
  
  .upload-button:disabled,
  .sample-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .status-message {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #555;
  }
  
  .status-message.success {
    color: #28a745;
  }
  
  .status-message.error {
    color: #dc3545;
  }
  
  @media (max-width: 600px) {
    .upload-actions {
      flex-direction: column;
    }
  
    .upload-button,
    .sample-button {
      width: 100%;
    }
  }
  