.formContainer {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #34495e;
}

/* Removed calc() and margin-right for consistent spacing */
.formGroup input[type="email"], .formGroup select, .formGroup textarea {
  width: 100%; /* Full width for inputs */
  padding: 0.75rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.formGroup textarea {
  height: 150px;
  resize: vertical;
}

.submitButton {
  display: block;
  width: 100%; /* Full width for button */
  padding: 1rem;
  border: none;
  background-color: #2ecc71;
  color: white;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #27ae60;
}

.questionGroup {
  border-top: 1px solid #ecf0f1;
  padding-top: 1rem;
  margin-top: 1rem;
}

.questionLabel {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.radioOptions {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.radioOptions label {
  padding: 0.75rem; 
  background-color: #ecf0f1;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 40px;
  text-align: center;
}

.radioOptions input[type="radio"] {
  display: none;
}

.radioOptions input[type="radio"]:checked + label {
  background-color: #3498db;
  color: white;
}

.radioContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px; /* Adjust spacing as needed */
}

.legendText {
  font-size: 0.8em; /* Adjust size as needed */
  text-align: center;
  margin-bottom: 4px; /* Space between text and radio button */
}

.radioLabel {
  cursor: pointer; /* Makes the whole label clickable */
}

.legendNumber {
  font-size: 0.8em; /* Adjust size as needed */
  margin-bottom: 4px; /* Space between number and radio button */
}

@media (max-width: 768px) {
  .formContainer {
    padding: 1rem;
  }

  .formGroup textarea {
    height: 120px;
  }
}
