.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ecf0f1;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
}

.login-form button {
  padding: 10px 20px;
  color: white;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.login-form button:hover {
  background-color: #2980b9;
}

.error-message {
  color: red;
  /* Additional styling as needed */
}

/* Responsive Design for Mobile */
@media (max-width: 600px) {
  .login-form {
      padding: 15px;
      margin: 0 10px; /* Adds margin to the sides on small screens */
  }

  .login-form input, .login-form button {
      padding: 8px; /* Slightly smaller padding */
      margin: 8px 0; /* Reduced margin */
  }

  .login-form button {
      padding: 8px 15px;
  }

  /* Optional: Adjust font size for smaller screens */
  .login-form input, .login-form button, .error-message {
      font-size: 0.9em;
  }
}
