@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.admin-ai-dashboard-container {
  max-width: 1200px;
  margin: 80px auto 30px; /* Add extra top margin if navbar overlaps */
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  font-family: 'Roboto', sans-serif;
}

.admin-ai-dashboard-container h2 {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  color: #333;
}

.ai-dashboard-layout {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 20px;
}

/* Leader Selection Panel */
.leader-selection-panel {
  background: #f9fafc;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.search-bar-container {
  margin-bottom: 15px;
}

.search-bar {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-weight: 600;
  text-align: center;
}

.leader-list {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  overflow-y: auto;
}

.leader-list li {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.leader-list li:hover {
  background-color: #e0f7ff;
}

.active-leader {
  background-color: #ccefff !important;
  border-color: #99ddff;
}

.leader-email {
  font-size: 0.9rem;
  color: #777;
}
.leader-org {
  font-size: 0.9rem;
  color: #555;
}

/* Readiness Indicator */
.readiness-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 6px;
}

.readiness-indicator .bar {
  height: 10px;
  width: 40px;
  border-radius: 5px;
}

.bar.low {
  background-color: #ff4d4f;
}
.bar.medium {
  background-color: #ffa940;
}
.bar.hot {
  background-color: #52c41a;
}

/* AI Chat Panel */
.ai-chat-panel {
  /* Remove or change position to avoid overlap with navbar */
  /* position: relative; */
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  min-height: 500px; /* ensures enough space for chat, can adjust */
}

.loading-overlay {
  /* Instead of position: absolute, make a simple overlay with fixed or remove it altogether */
  position: fixed;
  background: rgba(255,255,255,0.8);
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-size: 1.2rem;
  color: #444;
  font-weight: 600;
}

.leader-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.leader-header h3 {
  margin: 0;
  color: #333;
  font-weight: 500;
}
.leader-header p {
  margin: 4px 0;
  font-size: 0.95rem;
}

.conversation-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.no-convo-message {
  color: #666;
  font-size: 0.95rem;
}

.chat-pair {
  margin-bottom: 15px;
}

.chat-question {
  background: #e6f7ff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 6px;
  font-size: 0.95rem;
  color: #333;
}

.chat-answer {
  background: #f0fff0;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.95rem;
  color: #333;
}

.chat-answer pre {
  margin: 0;
  white-space: pre-wrap;
  font-family: 'Roboto Mono', monospace;
  color: #444;
}

.chat-input-section {
  display: flex;
  flex-direction: column;
}

.chat-input-section textarea {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  resize: vertical;
  font-size: 0.95rem;
}

.chat-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.chat-actions button {
  background-color: #3498db;
  border: none;
  color: #fff;
  border-radius: 4px;
  padding: 8px 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.95rem;
  font-weight: 500;
}

.chat-actions button:hover {
  background-color: #2980b9;
}

.no-leader-selected {
  text-align: center;
  color: #555;
  margin-top: 40px;
  font-style: italic;
}

@media (max-width: 900px) {
  .ai-dashboard-layout {
    grid-template-columns: 1fr;
  }

  .leader-selection-panel {
    margin-bottom: 15px;
  }
}
