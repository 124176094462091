/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Base Styles */
body {
  background: #f5f7fa;
  font-family: 'Roboto', sans-serif;
  color: #4a4a4a;
  line-height: 1.6;
}

h2 {
  color: #333;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.subHeader {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.inputGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.inputGroup input, .inputGroup select, .inputGroup button {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.inputGroup input:focus, .inputGroup select:focus {
  border-color: #4a90e2;
}

.inputGroup button {
  background-color: #4a90e2;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 700;
  transition: background 0.3s ease;
}

.inputGroup button:hover {
  background-color: #357ab7;
}

.participantList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.participantList li {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr auto;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.deleteButton {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.deleteButton:hover {
  background-color: #c0392b;
}

.error {
  color: #c0392b;
  text-align: center;
  margin-bottom: 10px;
}

.readyToNotifyToggle {
  text-align: center;
  margin-top: 20px;
}

.readyToNotifyToggle label {
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.readyToNotifyToggle input[type="checkbox"] {
  margin-right: 10px;
}

.disabledButton {
  background-color: #ccc;
  cursor: not-allowed;
}

.disabledButton:hover {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .inputGroup {
    grid-template-columns: 1fr;
  }
  .participantList li {
    grid-template-columns: 1fr;
    align-items: flex-start;
  }
}
