/* Base Styles */
.status-report-container {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ensure content doesn't overflow */
  }
  
  .status-report-container h2 {
    color: #333;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .status-report-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .status-report-table thead {
    background: #f9fafc;
  }
  
  .status-report-table thead tr th {
    padding: 10px;
    text-align: left;
    font-weight: 700;
    border-bottom: 2px solid #e0e0e0; /* Thicker bottom border */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .status-report-table tbody tr td {
    padding: 10px;
    border-bottom: 2px solid #e0e0e0; /* Thicker bottom border for rows */
    word-break: break-word;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .status-report-table tbody tr.red-row {
    background-color: #f8d7da; /* Light red background for rows that are red */
    color: #721c24; /* Darker red text */
  }
  
  .status-report-table tbody tr.yellow-row {
    background-color: #fff3cd; /* Light yellow background for rows that are yellow */
    color: #856404; /* Darker yellow text */
  }
  
  .status-report-table tbody tr.green-row {
    background-color: #d4edda; /* Light green background for rows that are green */
    color: #155724; /* Darker green text */
  }
  
  .status-report-table tbody tr:hover {
    background-color: #f1f1f1; /* Light grey background on hover */
  }
  
  .export-pdf-button {
    background-color: #4a90e2;
    color: #fff;
    font-weight: 700;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
    display: inline-block;
  }
  
  .export-pdf-button:hover {
    background-color: #357ab7;
  }
  
  .export-pdf-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(53, 122, 183, 0.4);
  }
  
  .back-button {
    background-color: #6c757d;
    color: #fff;
    font-weight: 700;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
    margin-left: 10px;
    display: inline-block;
  }
  
  .back-button:hover {
    background-color: #5a6268;
  }
  
  .back-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(90, 98, 104, 0.4);
  }
  

  .legend {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .legend-item {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    font-weight: 700;
  }
  
  .legend-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1px solid #ccc;
  }
  
  .green-box {
    background-color: #d4edda; /* Same as green-row background */
  }
  
  .yellow-box {
    background-color: #fff3cd; /* Same as yellow-row background */
  }
  
  .red-box {
    background-color: #f8d7da; /* Same as red-row background */
  }