/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Base Styles */
body {
  background: #f5f7fa;
  font-family: 'Roboto', sans-serif;
  color: #4a4a4a;
  line-height: 1.6;
}

h2 {
  color: #333;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

/* Add box-sizing rule */
*, *::before, *::after {
  box-sizing: border-box;
}

.participant-maintenance-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensure content doesn't overflow */
}

.participant-maintenance-form-container h1 {
  color: #333;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  text-align: center;
}

.participant-maintenance-form-container form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.participant-maintenance-form-container form .form-group {
  display: flex;
  flex-direction: column;
}

.participant-maintenance-form-container form .form-group label {
  font-weight: 500;
  margin-bottom: 5px;
}

.participant-maintenance-form-container form .form-group input,
.participant-maintenance-form-container form .form-group select {
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.participant-maintenance-form-container form .form-group select:focus,
.participant-maintenance-form-container form .form-group input:focus {
  border-color: #4a90e2;
}

.participant-maintenance-form-container form .form-group select {
  background: #ffffff;
}

.participant-maintenance-form-container form .form-group input[type="submit"] {
  grid-column: span 2;
  background: #4a90e2;
  color: #fff;
  font-weight: 700;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.participant-maintenance-form-container form .form-group input[type="submit"]:hover {
  background: #357ab7;
}

.participant-list {
  margin: 20px 0;
  overflow-x: auto; /* Ensure table does not overflow */
}

.participant-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.participant-list table thead {
  background: #f9fafc;
}

.participant-list table thead tr th {
  padding: 10px;
  text-align: left;
  font-weight: 700;
  border-bottom: 2px solid #e0e0e0; /* Thicker bottom border */
  position: relative; /* To position sorting icons */
  white-space: nowrap; /* Prevent text from wrapping */
}

.participant-list table thead tr th::after {
  content: ' ⇅'; /* Default sorting icon */
  font-size: 12px;
  margin-left: 5px;
  position: absolute;
  right: 10px;
  opacity: 0.7;
}

.participant-list table thead tr th.sorted-asc::after {
  content: ' 🔼'; /* Ascending sorting icon */
}

.participant-list table thead tr th.sorted-desc::after {
  content: ' 🔽'; /* Descending sorting icon */
}

.participant-list table tbody tr td {
  padding: 10px;
  border-bottom: 2px solid #e0e0e0; /* Thicker bottom border for rows */
  word-break: break-word;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}

.participant-list .participant-row td {
  border-bottom: none; /* Remove bottom border for participant row */
}

.participant-list .action-row td {
  border-top: none; /* Remove top border for action row */
  border-bottom: 2px solid #e0e0e0; /* Thicker bottom border for action row */
}

.participant-list .action-buttons {
  display: flex;
  justify-content: flex-start; /* Left align the action buttons */
  gap: 10px; /* Add some space between the buttons */
  padding: 10px 0;
}

.participant-list .action-buttons button {
  background: #4a90e2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.participant-list .action-buttons button:hover {
  background: #357ab7;
}

.search-bar {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  max-width: 100%; /* Ensure search bar does not overflow */
}

.email-status-message {
  margin-top: 10px;
  font-size: 14px;
  color: white;
  text-align: center;
}

/* Ensure table columns have better width distribution */
.participant-list table thead th,
.participant-list table tbody td {
  min-width: 100px;
  max-width: 200px;
}

.participant-list table {
  table-layout: fixed; /* Fix table layout for better column width control */
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 5px 10px;
  border-radius: 5px;
}

.pagination li a:hover {
  background-color: #eeeeee;
}

.pagination li a:focus {
  outline: none;
}