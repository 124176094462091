/* NavigationBar.module.css */

/* Font (optional; can reuse same family as landing) */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.navbar {
  font-family: 'Orbitron', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  background: linear-gradient(to right, rgba(44, 62, 80, 0.95), rgba(52, 73, 94, 0.95));
  color: #ecf0f1;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Left side (brand logo/text) */
.navLeft {
  display: flex;
  align-items: center;
}

/* Right side (links, user info) */
.navRight {
  display: flex;
  align-items: center;
}

/* Brand styling */
.brand {
  text-decoration: none;
  color: #ecf0f1;
  font-size: 1.6em;
  font-weight: bold;
  margin-right: 1rem;
  transition: color 0.3s ease;
}
.brand:hover {
  color: #3498db;
}

/* Navigation link styling */
.navLink {
  text-decoration: none;
  color: #ecf0f1;
  margin-right: 1rem;
  font-size: 1rem;
  position: relative;
  padding: 6px 8px;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.navLink:hover,
.navLink:focus {
  background-color: #3498db;
  color: #ffffff;
}

/* A button for sign out or other actions */
.navButton {
  background-color: transparent;
  color: #ecf0f1;
  border: 2px solid #ecf0f1;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  transition: all 0.3s;
}

.navButton:hover {
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
}

/* User info text */
.userInfo {
  margin-right: 1rem;
  font-size: 0.95rem;
  color: #bdc3c7;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 0.5rem;
  }

  .navLeft, .navRight {
    width: 100%;
    justify-content: center;
    margin: 0.5rem 0;
  }

  .navLink {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .navButton {
    margin: 0.5rem;
    width: auto;
  }
}
