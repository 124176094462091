/* LandingPage.css */

/* Font: Orbitron for a professional/futuristic vibe */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: 'Orbitron', sans-serif;
  background-color: #ecf0f1; /* Light fallback background */
  color: #ecf0f1;
  height: 100%;
  width: 100%;
}

/* ------------------------------
   CONTAINER
------------------------------ */
.landing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* ------------------------------
   HERO SECTION
------------------------------ */
.landing-hero {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport */
  background: url("https://images.unsplash.com/photo-1518241353330-ecc43c6d34df?fit=crop&w=1600&q=80") 
    no-repeat center center/cover;
  /* Fallback gradient if image fails:
     background: linear-gradient(to bottom right, #2c3e50, #34495e); 
  */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 62, 80, 0.55); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-content {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeInUp 1.2s ease forwards;
  opacity: 0;
  transform: translateY(20px);
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
}

.landing-content p {
  font-size: 1.3rem;
  margin-bottom: 30px;
  color: #ecf0f1;
}

.contact-button {
  font-size: 1rem;
  padding: 15px 30px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

.contact-button:hover {
  background-color: #2980b9;
}

/* Scroll indicator */
.scroll-indicator {
  margin-top: 40px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  color: #ecf0f1;
  font-weight: 500;
}

.scroll-text {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.arrow-down {
  font-size: 1.5rem;
  animation: bounceDown 1.3s infinite;
}

@keyframes bounceDown {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(8px); }
  60% { transform: translateY(4px); }
}

/* ------------------------------
   SECONDARY INFO SECTION
------------------------------ */
.landing-info-section {
  background-color: #fff; /* White for contrast */
  color: #2c3e50; 
  padding: 60px 20px;
  text-align: center;
}

.landing-info-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.landing-info-section p {
  max-width: 800px;
  margin: 0 auto 20px auto;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #4c4c4c;
}

/* Tzen Analytics branding area */
.tzen-branding {
  margin-top: 50px;
}

.tzen-branding h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #2c3e50;
}

/* Genome spiral container + text */
.genome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #34495e;
  font-size: 1rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.genome-container p {
  margin-top: 15px;
  line-height: 1.5;
  text-align: center;
  color: #555;
}

/* The spiral genome: a small circle that rotates, simulating a "DNA" swirl */
.spiral-genome {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: conic-gradient(
    #3498db 0%,
    #8e44ad 25%,
    #f1c40f 50%,
    #e74c3c 75%,
    #3498db 100%
  );
  animation: spinGenome 3s linear infinite;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

@keyframes spinGenome {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .landing-content h1 {
    font-size: 2.2rem;
  }
  .landing-content p {
    font-size: 1.1rem;
  }
  .spiral-genome {
    width: 60px;
    height: 60px;
  }
}

