/* MainIndex.css */

/* Base styles */
.main-index-container {
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Header */
  .main-index-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .main-index-header h1 {
    font-size: 2.4rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .main-index-subtitle {
    font-size: 1.1rem;
    color: #555;
  }
  
  /* Grid of cards */
  .index-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Individual card */
  .index-card {
    background: #fff;
    border: 2px solid #007bff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: box-shadow 0.3s, transform 0.3s;
  }
  
  .index-card:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }
  
  /* Card title */
  .card-title {
    margin-bottom: 10px;
    color: #007bff;
    font-size: 1.3rem;
  }
  
  /* Optional card description */
  .card-description {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  
  /* Link button */
  .card-button {
    display: inline-block;
    text-decoration: none;
    background: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    border: none; /* If you want a 'button' style */
    cursor: pointer;
  }
  
  .card-button:hover {
    background: #0056b3;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .index-cards-grid {
      grid-template-columns: 1fr;
    }
    .index-card {
      margin: 0 auto;
      max-width: 400px;
    }
    .main-index-header h1 {
      font-size: 1.8rem;
    }
  }
  