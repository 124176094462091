@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.customer-maintenance-container {
  width: 95%;
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  font-family: 'Roboto', sans-serif;
}

.customer-maintenance-container h2 {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  color: #333;
  font-weight: 700;
}

.customer-maintenance-form-container form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.customer-maintenance-form-container .form-group {
  display: flex;
  flex-direction: column;
}

.customer-maintenance-form-container .form-group label {
  margin-bottom: 5px;
  font-weight: 500;
}

.customer-maintenance-form-container .form-group input,
.customer-maintenance-form-container .form-group select,
.customer-maintenance-form-container .form-group textarea {
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.customer-maintenance-form-container .form-group.full-submit {
  grid-column: 1 / -1;
}

.customer-maintenance-form-container input[type="submit"] {
  background: #4a90e2;
  color: #fff;
  font-weight: 700;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.customer-maintenance-form-container input[type="submit"]:hover {
  background: #357ab7;
}

@media (max-width: 1024px) {
  .customer-maintenance-form-container form {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .customer-maintenance-form-container form {
    grid-template-columns: 1fr;
  }
}

.customer-list {
  margin-top: 20px;
}

.search-bar {
  display: block;
  width: 100%;
  max-width: 350px;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 0.95rem;
  table-layout: fixed;
  word-wrap: break-word;
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: top;
  overflow-wrap: anywhere;
}

tbody tr:nth-child(even) {
  background: #f9f9f9;
}

th.sorted-asc::after,
th.sorted-desc::after {
  content: '';
  margin-left: 8px;
  border: 4px solid transparent;
}

th.sorted-asc::after {
  border-bottom-color: #333;
}

th.sorted-desc::after {
  border-top-color: #333;
}

.action-row td {
  border-top: none;
  background: #fafafa;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.action-buttons button {
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.3s;
}

.action-buttons button:hover {
  background-color: #357ab7;
}

.custom-message {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  font-family: 'Roboto', sans-serif;
  resize: vertical;
}

.completeness-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
}

.completeness-indicator .bar {
  height: 10px;
  width: 40px;
  border-radius: 5px;
}

.bar.low {
  background-color: #ff4d4f;
}

.bar.medium {
  background-color: #ffa940;
}

.bar.hot {
  background-color: #52c41a;
}

.email-status-message {
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  color: #4a90e2;
}

.Modal {
  position: relative;
  max-width: 500px;
  margin: 100px auto;
  background: #fff;
  padding: 20px;
  outline: none;
  border-radius: 8px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
}

.Modal h2 {
  margin-top: 0;
}

.Modal button {
  background: #4a90e2;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 20px;
}

.Modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
