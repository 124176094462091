@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  background: #f5f7fa;
  font-family: 'Roboto', sans-serif;
  color: #4a4a4a;
  line-height: 1.6;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.products-container {
  width: 95%;
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.product-maintenance-container {
  background: #ffffff;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.product-maintenance-container h1 {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  text-align: center;
  color: #333;
}

.product-maintenance-container form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group.full-submit {
  grid-column: 1 / -1;
}

.product-maintenance-container label {
  font-weight: 500;
  margin-bottom: 5px;
}

.product-maintenance-container input,
.product-maintenance-container select,
.product-maintenance-container button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.product-maintenance-container button {
  background-color: #4CAF50;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
}

.product-maintenance-container button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .product-maintenance-container form {
    grid-template-columns: 1fr;
  }
}

.product-list-container {
  margin-top: 2rem;
}

.product-list-container h2 {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  text-align: center;
  color: #333;
}

.product-list {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  table-layout: fixed;
  word-wrap: break-word;
}

.product-list th, .product-list td {
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
  vertical-align: top;
  overflow-wrap: anywhere;
}

.product-list th {
  background: #f9fafc;
  font-weight: 700;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}

.action-buttons button {
  background: #4a90e2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.action-buttons button:hover {
  background: #357ab7;
}

.delete-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: red;
  font-size: 24px;
  cursor: pointer;
  border: 2px solid red;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.delete-icon-container:hover {
  background-color: rgba(255, 0, 0, 0.1);
  transform: translateY(2px) scale(1.1);
}

.delete-icon-container:active {
  transform: scale(0.95);
}

.disabled-delete-icon {
  display: inline-block;
  color: grey;
  font-size: 24px;
  opacity: 0.6;
  cursor: not-allowed;
}
