/* LeaderAnalytics.module.css */

.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .subHeader {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  
  .chartContainer {
    margin-top: 20px;
  }
  
  .aiContainer {
    margin-bottom: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .aiResponse {
    white-space: pre-wrap;
    font-size: 16px;
    color: #333;
  }
  
  .loading {
    font-size: 16px;
    color: #999;
  }
  
  .customTooltip {
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
  }
  
  .label {
    font-weight: bold;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    margin-top: 10px;
    padding: 10px 20px;
    background: #4a90e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background: #357ab7;
  }
  